<template>
  <div class="videogramme-outer">
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="videogramme-inner">
              <p>In order to send a videogramme, first record a video using the native camera app on your smart phone.</p>
              <p>Once recorded, upload the video by clicking the button below.</p>
              <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form class="login-form text-left" @submit.prevent="passes(onSubmit)">
                  <div class="m-0">
                    <div class="mt-4">
                      <label class="w-100">
                        <div class="required-field">
                          <label class="color-text mb-0">Title</label>
                        </div>
                        <span class="w-100">
                          <ValidationProvider
                            name="Title"
                            rules="required"
                            v-slot="{ valid, errors }"
                          >
                            <b-form-input v-model="videoTitle" :disabled="disableTitle"
                            :state="errors[0] ? false : (valid ? true : null)"
                            @change="upload_video($event, 'title')" class="w-100 videoInputTitle"></b-form-input>
                            <p class="feedback-error" v-if="errors[0] || reqTitle == true">{{ errors[0] | title_message }}</p>
                          </ValidationProvider>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="m-0">
                    <div class="mt-4">
                      <label class="w-100">
                        <label class="color-text mb-0">Description</label>
                        <span class="w-100">
                          <b-form-textarea v-model="videoDescription" class="w-100 videoInputTitle"
                            id="videoDescription" rows="6" no-resize name="videoDescription"
                          ></b-form-textarea>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="justify-content-space-between" style="align-items: flex-end;">
                    <div class="width-100">
                      <ValidationProvider name="Video" rules="required|ext:mp4,mov,flv,avi,wmv|size:1048576" v-slot="{ valid, errors }">
                        <b-form-file :disabled="videoTitle.trim() == '' || uploadPercentage > 0" accept="video/mp4, video/avi, video/x-flv, video/quicktime, video/x-ms-wmv, .mp4, .mov, .flv, .avi, .wmv"
                          class="pdf-btn video-add-btn my-3" v-model="videoInput"
                          :state="errors[0] ? false : (valid ? true : null)"
                          @change="upload_video($event, 'video')" browse-text="Browse videos to upload"
                        ></b-form-file>
                        <p class="feedback-error" v-if="errors[0]">{{ errors[0] | custom_message }}</p>
                        <p class="feedback-error" v-if="videoValidError && !errors[0]">{{ videoValidError }}</p>
                      </ValidationProvider>
                    </div>
                    <div class="mb-2 ml-2">
                      <b-icon v-b-tooltip.hover.right="'Only MP4 is allowed. Maximum size allowed is 1GB. File name must not contain any spaces or special characters with the expection of \'_\' , \'-\' and \'.\''" icon="info" class="info-icon-size"></b-icon>
                    </div>
                  </div>
                  <video-player v-if="video_src" class="video-player-box brief-video"
                    ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"
                    customEventName="customstatechangedeventname"
                    @statechanged="playerStateChanged($event)">
                  </video-player>
                  <div v-if="uploadPercentage > 0">
                    <b-progress class="video-progress-bar" :value="uploadPercentage" :max="100" show-progress animated></b-progress>
                    <div class="upload-percentage"><span v-if="uploadPercentage == 100">Completed</span><span v-else>Uploading {{ uploadPercentage }}% ...</span></div>
                  </div>
                  <b-row>
                  <b-col>
                  <div class="" v-if="videoname"><b-button v-if="!deleteEnable" @click.prevent="cancel()">Cancel</b-button><b-button v-else v-b-modal.confirm-delete @click.prevent="delete_popup()">Delete</b-button></div>
                  </b-col>
                  <b-col>
                  <div class="clearfix"></div>
                    <b-button variant="light" class="float-right" :disabled="enablenext" @click.prevent="next_page">Next</b-button>
                  <div class="clearfix"></div>
                  </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <confirmModal></confirmModal>
  </div>
</template>
<script>
import play_back from "../../../assets/images/play_button.png";
import { isloggedin, BASE_URL_VER } from "../../../constants/app-constants";
import { HTTP, HTTP_imageupload } from "../../../constants/http-common";
import { EventBus } from "../../../main";
import { videoPlayer } from 'vue-video-player';
import confirmModal from "../../../components/admin_layouts/dashboard_layouts/status_message/confirm_resp";
export default {
  components: {
    videoPlayer,
    confirmModal
  },
  data() {
    return {
      public_id: "",
      videoname: "",
      enablenext: true,
      video_src: "",
      uploadPercentage: 0,
      video: {
        sources: [
          {
            src: "",
            type: "video/mp4"
          }
        ],
        options: {
          autoplay: false,
          volume: 0.6,
          poster: play_back
        },
        errorMsg: "now working!"
      },
      videoTitle: "",
      reqTitle: false,
      disableTitle: false,
      formData: "",
      playerOptions: {
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: ""
        }],
      },
      videoInput: null,
      videoDescription: "",
      videoPublicId: "",
      request: null,
      deleteEnable: false,
      videoValidError: "",
      mediaId: ""
    };
  },
  filters: {
    custom_message: function(value) { // 2097152KB - 2 GB
      return ( value == "The Video field is not a valid file" || value == "The Video field size must be less than 1048576KB" ) ? "Select a valid video file" : value;
    },
    title_message: function(value) {
        return "Enter the title for the video";
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    next_page() {
      this.$router.push({ path: "/distribute/videogramme/" + this.videoPublicId });
    },
    upload_video(event, action) {
      if (action == "video") {
        // let rg = /^[^()@&\s\+]+$/;
        let rg = /^[a-zA-Z\d\-_\.]+$/i;
        if ( event.target.files.length > 0 && ( event.target.files[0].size <= 1073741824 ) && ( rg.test( event.target.files[0].name ) ) && ( event.target.files[0].name.substring( event.target.files[0].name.length - 4 ) == ".flv" || event.target.files[0].type == "video/mp4" || event.target.files[0].type == "mp4" || event.target.files[0].type == "video/avi" || event.target.files[0].type == "avi" || event.target.files[0].type == "video/x-flv" || event.target.files[0].type == "flv" || event.target.files[0].type == "video/quicktime" || event.target.files[0].type == "mov" || event.target.files[0].type == "video/x-ms-wmv" || event.target.files[0].type == "wmv" ) ) {
          this.videoValidError = "";
          this.videoname = event.target.files[0].name
          this.enablenext = true;
          this.deleteEnable = false
          this.disableTitle = true;
          this.video_src = "";// URL.createObjectURL(event.target.files[0]);
          this.playerOptions.sources[0].src = "";// URL.createObjectURL(event.target.files[0]);
          this.formData = new FormData();
          this.formData.set( "broadcast", event.target.files[0]);
          if ( this.videoTitle == "" ) {
            this.reqTitle = true;
            setTimeout(() => {
              this.reqTitle = false;
            }, 2000);
          }
        } else {
          this.videoValidError = 'Select a valid video file';
        }
      }
      if ( this.videoname != "" && this.videoTitle != "" ) {
        this.video_upload()
      }
      // this.videoInput = null
    },
    cancel() {
      if ( this.request ) {
        this.request.cancel();
        this.clearOldRequest();
      }
    },
    clearOldRequest() {
      this.request = null;
      this.videoInput = null
      this.$nextTick(() => {
        if (this.$refs.observer) {
          this.$refs.observer.reset();
        }
      });
    },
    async video_upload() {
      this.disableTitle = true;
      this.videoValidError = "";
      const axiosSource = axios.CancelToken.source();
      this.request = { cancel: axiosSource.cancel };
      let videoId = (this.videoPublicId) ? "&public_id=" + this.videoPublicId : "";
      await HTTP_imageupload.post("broadcast/add_broadcast?title=" + this.videoTitle + "&description=" + this.videoDescription + videoId, this.formData, {
        cancelToken: axiosSource.token,
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 80)
          );
        }.bind(this)
      })
      .then(response => {
        this.clearOldRequest();
        this.uploadPercentage = 100; // Set to 100% on successful upload
        this.mediaId = response.data.media_convert_id;
        this.videoPublicId = response.data.public_id;
        this.videoname = response.data.file_name;
        this.video_src = response.data.file;
        this.playerOptions.sources[0].src = response.data.file;
        this.enablenext = false;
        this.deleteEnable = true;
        this.disableTitle = false;
        this.videoInput = null;
        this.$nextTick(() => {
          if (this.$refs.observer) {
            this.$refs.observer.reset();
          }
        });
        let res = {
          popup: true,
          msg: "Videogramme Saved Successfully."
        };
        EventBus.$emit("success_resp", res);
      })
      .catch(error => {
        this.clearOldRequest();
        this.enablenext = true;
        this.deleteEnable = false;
        this.uploadPercentage = 0;
        this.videoname = "";
        this.video_src = "";
        this.disableTitle = false;
        this.playerOptions.sources[0].src = "";
        this.videoInput = null;
        this.$nextTick(() => {
          if (this.$refs.observer) {
            this.$refs.observer.reset();
          }
        });
        if (error.response.status == 400) {
          let response = {
            popup: true,
            msg: error.response.data.message
          };
          EventBus.$emit("error_resp", response);
        }
      });
    },
    mediaConvertStatusCheck() {
      HTTP.get( "media_converts/get_media_convert_status/" + this.mediaId + "?broadcast_id=" + this.videoPublicId )
        .then(resp => {
          if ( resp.status == 200 ) {
            if ( resp.data.status == "IN-PROGRESS" ) {
              setTimeout(() => {
                this.mediaConvertStatusCheck();
              }, 5000);
            } else {
              this.uploadPercentage = this.uploadPercentage + 20;
              setTimeout(() => {
                this.uploadPercentage = 0;
              }, 2500);
              this.video_src = resp.data.converted_file;
              this.playerOptions.sources[0].src = resp.data.converted_file;
              this.videoname = resp.data.converted_file_name;
              this.enablenext = false
              this.deleteEnable = true
              this.disableTitle = false;
              this.videoInput = null
              this.$nextTick(() => {
                if (this.$refs.observer) {
                  this.$refs.observer.reset();
                }
              });
              let res = {
                popup: true,
                msg: "Videogramme Saved Successfully."
              }
              EventBus.$emit( "success_resp", res )
            }
          }
        })
        .catch(error => {
          this.enablenext = true
          this.deleteEnable = false
          this.uploadPercentage = 0;
          this.videoname = ""
          this.video_src = "";
          this.disableTitle = false;
          this.playerOptions.sources[0].src = "";
          this.videoInput = null
          this.videoPublicId = "";
          this.mediaId = ""
          this.$nextTick(() => {
            if (this.$refs.observer) {
              this.$refs.observer.reset();
            }
          });
          if ( error.response.status == 400 ) {
            let response = {
              popup: true,
              msg: "Failed to Save Videogramme"
            };
            EventBus.$emit("error_resp", response)
          }
        });
    },
    deleteVideo() {
      this.videoValidError = "";
      HTTP.delete("broadcasts/delete_broadcast/" + this.videoPublicId)
        .then(response => {
          if ( response.data.code == 200 ) {
            let resp = {
              msg: response.data.message,
              popup: true
            }
            EventBus.$emit("success_resp", resp);
            this.enablenext = true
            this.deleteEnable = false
            this.uploadPercentage = 0;
            this.videoname = ""
            this.video_src = "";
            this.disableTitle = false;
            this.playerOptions.sources[0].src = "";
            this.videoPublicId = ""
            this.videoInput = null
            this.$nextTick(() => {
              if (this.$refs.observerEdit) {
                this.$refs.observerEdit.reset();
              }
            });
          }
        })
        .catch(err => {
          if ( err.response.status == 400 ) {
            let resp = {
              msg: err.response.data.message,
              popup: true
            }
            EventBus.$emit("error_resp", resp);
            this.enablenext = true
            this.deleteEnable = false
            this.uploadPercentage = 0;
            this.videoname = ""
            this.video_src = "";
            this.disableTitle = false;
            this.playerOptions.sources[0].src = "";
            this.videoPublicId = ""
            this.videoInput = null
            this.$nextTick(() => {
              if (this.$refs.observer) {
                this.$refs.observer.reset();
              }
            });
          }
        });
    },
    delete_popup() {
      EventBus.$emit( "deleteSelected", this.videoTitle + " Video" );
    }
  },
  created() {
    EventBus.$on( "deleteConfirmation", response => {
      if ( response == "delete" ) {
        this.deleteVideo();
      }
    });
  },
  beforeCreate() {
    EventBus.$off("deleteConfirmation")
  }
};
</script>
<style scoped>
@import "../def_function.css";
@import "../videogramme.css";
.videogramme-outer input.videoInputTitle {
    border: 1px solid #d7d7d7;
}
.videogramme-outer input.videoInputTitle.form-control:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.videogramme-outer input.videoInputTitle.form-control.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.videogramme-outer input.videoInputTitle.form-control.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
</style>