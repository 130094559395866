<template>
  <div class="content-sidebar">
    <h5 class="sidebar-title">Set Access Rights</h5>
    <h6 class="sidebar-subtitle">{{ grpName }}</h6>
    <ValidationObserver ref="observer" v-slot="{ passes }">
      <b-form @submit.prevent="passes( popupBox )">
        <b-tabs class="access-tabs" :class="( loggedin_user_details.shuttle_tracker == 'TRUE' ) ? 'five-tabs' : 'four-tabs'" >
          <b-tab title="Admin" id="collapse-admin" accordion="access-accordion">
            <b-form-checkbox-group class="access-rights dc-access-label" name="admin" v-model="accessRightsData.accessRights">
              <b-form-checkbox value="Define Functions"><p>Define Functions</p></b-form-checkbox>
              <b-form-checkbox value="Organisation Set Up"><p>Organisation Set Up</p></b-form-checkbox>
              <b-form-checkbox value="Set Up Station"><p>Set Up Stations</p></b-form-checkbox>
              <b-form-checkbox value="Employee Groups"><p>Employee Groups</p></b-form-checkbox>
              <b-form-checkbox value="Manage Users">
              <b-row>
                <b-col sm="12" md="4" lg="3" class="pr-0"><p>Manage Users</p></b-col>
                <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                  <ValidationProvider name="Manage Users Scope" :rules="`${ (accessRightsData.accessRights.includes('Manage Users')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('Manage Users')" v-model="accessRightsData.geographicalRights.manageUsers" :options="scopeOptions" name="manageUsers"></b-form-radio-group>
                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-tab>
          <b-tab title="Briefing" id="collapse-briefing" accordion="access-accordion">
            <b-form-checkbox-group class="access-rights dc-access-label" name="admin" v-model="accessRightsData.accessRights">
              <b-form-checkbox value="Create Briefing" @input="dependencyCheck($event)">
              <b-row>
                <b-col sm="12" md="4" lg="3" class="pr-0"><p>Create Briefing</p></b-col>
                <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                  <ValidationProvider name="Create Briefing Scope" :rules="`${ (accessRightsData.accessRights.includes('Create Briefing')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('Create Briefing')" v-model="accessRightsData.geographicalRights.createBriefing" :options="scopeOptions" name="createBriefing"></b-form-radio-group>
                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              </b-form-checkbox>
              <b-form-checkbox value="List Briefing" :disabled="accessRightsData.accessRights.includes('Create Briefing')">
              <b-row>
                <b-col sm="12" md="4" lg="3" class="pr-0"><p>List Briefings</p></b-col>
                <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                  <ValidationProvider name="List Briefings Scope" :rules="`${ (accessRightsData.accessRights.includes('List Briefing')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('List Briefing')" v-model="accessRightsData.geographicalRights.listBriefing" :options="scopeOptions" name="listBriefing"></b-form-radio-group>
                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              </b-form-checkbox>
              <b-form-checkbox value="Briefing Reports">
              <b-row>
                <b-col sm="12" md="4" lg="3" class="pr-0"><p>Briefing Reports</p></b-col>
                <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                  <ValidationProvider name="Briefing Reports Scope" :rules="`${ (accessRightsData.accessRights.includes('Briefing Reports')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('Briefing Reports')" v-model="accessRightsData.geographicalRights.briefingReports" :options="scopeOptions" name="briefingReports"></b-form-radio-group>
                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              </b-form-checkbox>
              <b-form-checkbox value="Upload Videogramme" @input="dependencyCheck($event)">
              <b-row>
                <b-col sm="12" md="4" lg="3" class="pr-0"><p>Upload Videogramme</p></b-col>
                <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                  <ValidationProvider name="Upload Videogramme Scope" :rules="`${ (accessRightsData.accessRights.includes('Upload Videogramme')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('Upload Videogramme')" v-model="accessRightsData.geographicalRights.uploadVideogramme" :options="scopeOptions" name="uploadVideogramme"></b-form-radio-group>
                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              </b-form-checkbox>
              <b-form-checkbox value="List Videogrammes" :disabled="accessRightsData.accessRights.includes('Upload Videogramme')">
              <b-row>
                <b-col sm="12" md="4" lg="3" class="pr-0"><p>List Videogrammes</p></b-col>
                <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                  <ValidationProvider name="List Videogrammes Scope" :rules="`${ (accessRightsData.accessRights.includes('List Videogrammes')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('List Videogrammes')" v-model="accessRightsData.geographicalRights.listVideogrammes" :options="scopeOptions" name="listVideogrammes"></b-form-radio-group>
                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-tab>
          <b-tab title="Checklist" id="collapse-checklist" accordion="access-accordion">
            <b-form-checkbox-group class="access-rights dc-access-label" name="admin" v-model="accessRightsData.accessRights">
              <b-form-checkbox value="Global Checklist"><p>Define Checklists & Global Database</p></b-form-checkbox>
              <b-form-checkbox value="Set Up My Checklist">
              <b-row>
                <b-col sm="12" md="4" lg="3" class="pr-0"><p>Set Up My Checklist</p></b-col>
                <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                  <ValidationProvider name="Set Up My Checklist Scope" :rules="`${ (accessRightsData.accessRights.includes('Set Up My Checklist')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('Set Up My Checklist')" v-model="accessRightsData.geographicalRights.setUpMyChecklist" :options="scopeOptions" name="setUpMyChecklist"></b-form-radio-group>
                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              </b-form-checkbox>
              <b-form-checkbox value="Perform Checks"><p>Perform Checks</p></b-form-checkbox>
              <b-form-checkbox value="Checklist Summary">
              <b-row>
                <b-col sm="12" md="4" lg="3" class="pr-0"><p>Summary</p></b-col>
                <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                  <ValidationProvider name="Summary Scope" :rules="`${ (accessRightsData.accessRights.includes('Checklist Summary')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('Checklist Summary')" v-model="accessRightsData.geographicalRights.checklistSummary" :options="scopeOptions" name="checklistSummary"></b-form-radio-group>
                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              </b-form-checkbox>
              <b-form-checkbox value="Retrieve Reports">
              <b-row>
                <b-col sm="12" md="4" lg="3" class="pr-0"><p>Retrieve Reports</p></b-col>
                <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                  <ValidationProvider name="Retrieve Reports Scope" :rules="`${ (accessRightsData.accessRights.includes('Retrieve Reports')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('Retrieve Reports')" v-model="accessRightsData.geographicalRights.retrieveReports" :options="scopeOptions" name="retrieveReports"></b-form-radio-group>
                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              </b-form-checkbox>
              <b-form-checkbox value="List Checklists">
              <b-row>
                <b-col sm="12" md="4" lg="3" class="pr-0"><p>List Checklists</p></b-col>
                <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                  <ValidationProvider name="List Checklists Scope" :rules="`${ (accessRightsData.accessRights.includes('List Checklists')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('List Checklists')" v-model="accessRightsData.geographicalRights.listChecklists" :options="checkScopeOptions" name="listChecklists"><div class="dc-access-183-space custom-control-inline custom-radio"></div></b-form-radio-group>
                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-tab>
          <b-tab title="Training" id="collapse-training" accordion="access-accordion">
            <b-form-checkbox-group class="access-rights dc-access-label" name="admin" v-model="accessRightsData.accessRights">
              <!-- <b-form-checkbox value="Training Dashboard"><p>Training Dashboard</p></b-form-checkbox> -->
              <b-form-checkbox value="Set Up New Training">
              <b-row>
                <b-col sm="12" md="4" lg="3" class="pr-0"><p>Set Up New Training</p></b-col>
                <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                  <ValidationProvider name="Set Up New Training Scope" :rules="`${ (accessRightsData.accessRights.includes('Set Up New Training')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('Set Up New Training')" v-model="accessRightsData.geographicalRights.setUpNewTraining" :options="scopeOptions" name="setUpNewTraining"></b-form-radio-group>
                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              </b-form-checkbox>
              <b-form-checkbox value="My Trainings"><p>My Trainings</p></b-form-checkbox>
              <b-form-checkbox value="Training Results">
                <b-row>
                  <b-col sm="12" md="4" lg="3" class="pr-0"><p>Training Results</p></b-col>
                  <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                    <ValidationProvider name="Training Results Scope" :rules="`${ (accessRightsData.accessRights.includes('Training Results')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                      <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('Training Results')" v-model="accessRightsData.geographicalRights.trainingResults" :options="scopeOptions" name="trainingResults"></b-form-radio-group>
                      <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-form-checkbox>
              <b-form-checkbox value="List Trainings">
              <b-row>
                <b-col sm="12" md="4" lg="3" class="pr-0"><p>List Trainings</p></b-col>
                <b-col sm="12" md="8" lg="9" class="px-0 text-center">
                  <ValidationProvider name="List Trainings Scope" :rules="`${ (accessRightsData.accessRights.includes('List Trainings')) ? 'required' : ''}`" v-slot="{ valid, errors }">
                    <b-form-radio-group :state="errors[0] ? false : ( valid ? true : null )" :disabled="!accessRightsData.accessRights.includes('List Trainings')" v-model="accessRightsData.geographicalRights.listTrainings" :options="scopeOptions" name="listTrainings"></b-form-radio-group>
                    <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-col>
              </b-row>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-tab>
          <b-tab title="Incident Report" id="collapse-incident-report" accordion="access-accordion">
            <b-form-checkbox-group class="access-rights dc-access-label" name="admin" v-model="accessRightsData.accessRights">
              <b-form-checkbox value="My Incidents"><p>My Incidents</p></b-form-checkbox>
            </b-form-checkbox-group>
          </b-tab>
        </b-tabs>
        <div class="text-center">
          <div class="mx-auto">
            <button class="btn w-100 access-btn" :disabled="accessRightsData.accessRights.length == 0" type="submit" variant="light">{{ btnTxt }}</button>
            <!-- @click.prevent="popupBox" -->
          </div>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { HTTP_formdata } from "../../../constants/http-common";
import { EventBus } from "../../../main";
import { isloggedin } from "../../../constants/app-constants";
export default {
  data() {
    return {
      loggedin_user_details: isloggedin,
      accessRightsData: {
        accessRights: [],
        geographicalRights: {
          manageUsers: "",
          createBriefing: "",
          listBriefing: "",
          briefingReports: "",
          uploadVideogramme: "",
          listVideogrammes: "",
          setUpMyChecklist: "",
          checklistSummary: "",
          retrieveReports: "",
          listChecklists: "",
          setUpNewTraining: "",
          trainingResults: "",
          listTrainings: ""
        }
      },
      functionId: "",
      grpName: "",
      grpId: "",
      scopeOptions: [
        { text: 'Own Station', value: 'own_station' },
        { text: 'Org Code​', value: 'org_code' },
        { text: 'Function​', value: 'function' },
        { text: 'Global​', value: 'global' }
      ],
      checkScopeOptions: [
        { text: 'Own Station', value: 'own_station' },
        { text: 'Global​', value: 'global' }
      ],
      scopeArray: [
        "List Briefing", "Create Briefing", "Upload Videogramme", "Set Up My Checklist",
        "Checklist Summary", "Set Up New Training", "List Videogrammes", "List Checklists",
        "Retrieve Reports", "Training Results", "List Trainings", "Manage Users"
      ],
      addGrp: "",
      btnTxt: ""
    };
  },
  watch: {
    "accessRightsData.accessRights": {
      handler( newVal, oldVal ) {
        if ( newVal.length < oldVal.length ) {
          let diff = oldVal.filter( x => !newVal.includes( x ) );
          if ( diff.length > 0 && this.scopeArray.includes( diff[0] ) ) {
            let reference = diff[0].replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, ( camelCaseMatch, i ) => {
              if ( +camelCaseMatch === 0 ) return ""; return i === 0 ? camelCaseMatch.toLowerCase() : camelCaseMatch.toUpperCase();
            });
            this.accessRightsData.geographicalRights[reference] = "";
          }
        }
        deep: true;
      }
    }
  },
  methods: {
    dependencyCheck(event) {
      if ( event.includes('Create Briefing') && !event.includes("List Briefing") )
        this.accessRightsData.accessRights.push("List Briefing")
      if ( event.includes('Upload Videogramme') && !event.includes("List Videogrammes") )
        this.accessRightsData.accessRights.push("List Videogrammes")
      if ( ( event.includes('Set Up Parameter') || event.includes('Shuttle Overview') || event.includes('Plan Load') || event.includes('Dock Manager') || event.includes('Shuttle Tracker Report') ) && !event.includes("Shuttle Tracker Dashboard") )
        this.accessRightsData.accessRights.push("Shuttle Tracker Dashboard")
    },
    popupBox() {
      if ( this.addGrp ) {
        EventBus.$emit( "accessRightAdd", "add" );
      } else {
        this.accessRightsUpdate();
      }
    },
    accessRightsUpdate() {
      if ( this.grpId ) {
        let jsonData = {
          accessRights: this.accessRightsData.accessRights,
          geographicRights: {}
        };
        for (let [key, value] of Object.entries(this.accessRightsData.geographicalRights)) {
          if ( value != "" )
            jsonData.geographicRights[key] = value;
        }
        HTTP_formdata.put( "groups/access_rights/" + this.grpId, jsonData )
          .then(response => {
            let permissionData = {
              method_name: this.accessRightsData.accessRights,
              roles: [ this.grpName ]
            }
            HTTP_formdata.post( "permissions/add_permissions", permissionData )
              .then(resp => {
                let data = ( this.addGrp ) ? "Employee Group & Access Rights Added Successfully" : response.data;
                EventBus.$emit( "accessRights", data );
                this.accessRightsData.accessRights = [];
                this.accessRightsData.geographicalRights = {
                  manageUsers: "",
                  createBriefing: "",
                  listBriefing: "",
                  briefingReports: "",
                  uploadVideogramme: "",
                  listVideogrammes: "",
                  setUpMyChecklist: "",
                  checklistSummary: "",
                  retrieveReports: "",
                  listChecklists: "",
                  setUpNewTraining: "",
                  trainingResults: "",
                  listTrainings: ""
                }
                this.functionId = ""
                this.grpName = ""
                this.grpId = ""
                this.addGrp = ""
                this.btnTxt = ""
              })
              .catch(error => {});
          })
          .catch(err => {});
      }
    }
  },
  created() {
    EventBus.$on("selectGrpAccessRights", accessRightsData => {
      this.addGrp = ( accessRightsData.addGrp ) ? accessRightsData.addGrp : "";
      this.btnTxt = ( this.addGrp ) ? 'Add Employee Group & Access Rights' : 'Update Access Rights'
      this.accessRightsData.accessRights = (accessRightsData.accessRights == null) ? [] : accessRightsData.accessRights
      this.functionId = accessRightsData.function
      this.grpName = accessRightsData.grpTitle
      this.grpId = accessRightsData.grpId;
      if ( accessRightsData.accessRights != null && accessRightsData.geographicRights != null ) {
        this.accessRightsData.geographicalRights = {
          manageUsers: ( ! accessRightsData.geographicRights.manageUsers ) ? "" : accessRightsData.geographicRights.manageUsers,
          createBriefing: ( ! accessRightsData.geographicRights.createBriefing ) ? "" : accessRightsData.geographicRights.createBriefing,
          listBriefing: ( ! accessRightsData.geographicRights.listBriefing ) ? "" : accessRightsData.geographicRights.listBriefing,
          briefingReports: ( ! accessRightsData.geographicRights.briefingReports ) ? "" : accessRightsData.geographicRights.briefingReports,
          uploadVideogramme: ( ! accessRightsData.geographicRights.uploadVideogramme ) ? "" : accessRightsData.geographicRights.uploadVideogramme,
          listVideogrammes: ( ! accessRightsData.geographicRights.listVideogrammes ) ? "" : accessRightsData.geographicRights.listVideogrammes,
          setUpMyChecklist: ( ! accessRightsData.geographicRights.setUpMyChecklist ) ? "" : accessRightsData.geographicRights.setUpMyChecklist,
          checklistSummary: ( ! accessRightsData.geographicRights.checklistSummary ) ? "" : accessRightsData.geographicRights.checklistSummary,
          retrieveReports: ( ! accessRightsData.geographicRights.retrieveReports ) ? "" : accessRightsData.geographicRights.retrieveReports,
          listChecklists: ( ! accessRightsData.geographicRights.listChecklists ) ? "" : accessRightsData.geographicRights.listChecklists,
          setUpNewTraining: ( ! accessRightsData.geographicRights.setUpNewTraining ) ? "" : accessRightsData.geographicRights.setUpNewTraining,
          trainingResults: ( ! accessRightsData.geographicRights.trainingResults ) ? "" : accessRightsData.geographicRights.trainingResults,
          listTrainings: ( ! accessRightsData.geographicRights.listTrainings ) ? "" : accessRightsData.geographicRights.listTrainings
        };
      } else {
        this.accessRightsData.geographicalRights = {
          manageUsers: "",
          createBriefing: "",
          listBriefing: "",
          briefingReports: "",
          uploadVideogramme: "",
          listVideogrammes: "",
          setUpMyChecklist: "",
          checklistSummary: "",
          retrieveReports: "",
          listChecklists: "",
          setUpNewTraining: "",
          trainingResults: "",
          listTrainings: ""
        }
      }
    });
    EventBus.$on( "addAccessRight", response => {
      if ( response ) {
        this.grpName = response.group_name;
        this.grpId = response.public_id;
        this.accessRightsUpdate();
      }
    });
  },
  beforeCreate() {
    EventBus.$off("selectGrpAccessRights")
    EventBus.$off("addAccessRight")
  }
};
</script>
<style>
.five-tabs .nav-tabs li.nav-item {
  flex: 1;
}
.four-tabs .nav-tabs li.nav-item {
  width: 20%;
}
</style>