<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <div class="def-func-form">
                <ValidationObserver ref="observerTrainings" v-slot="{passes}">
                  <b-form @submit.prevent="passes( onSubmit )">
                    <div class="def-func-input-form-group">
                      <div class="set-up-checklist-row">
                        <b-row>
                          <b-col>
                            <div class="def-func-input-group">
                              <b-row>
                                <b-col md="12" lg="12">
                                  <div class="brief-rounded-sectn mb-4">
                                    <b-row>
                                      <b-col>
                                        <div class="required-field">
                                          <label class="distribute-group-label">Training Format</label>
                                        </div>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col md="12">
                                        <div class="distribute-group">
                                          <ValidationProvider name="Training Format" rules="required" v-slot="{ valid, errors }">
                                            <b-form-radio-group :state="errors[0] ? false : (valid ? true : null)" :options="trainingFormatOptions"
                                              name="trainingFormat" class="add-media-inner training-group" v-model="form_data.training_format">
                                            </b-form-radio-group>
                                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                          </ValidationProvider>
                                        </div>
                                      </b-col>
                                    </b-row>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row v-if="form_data.training_format">
                          <b-col>
                            <div class="def-func-input-group">
                              <b-row>
                                <b-col md="6">
                                  <ValidationProvider name="Name of Training" rules="required" v-slot="{ valid, errors }">
                                    <div class="required-field">
                                      <label for="NameofTraining">Name of Training</label>
                                      <b-input-group>
                                        <b-form-input id="NameofTraining" :state="errors[0] ? false : (valid ? true : null)"
                                          v-model="form_data.name_of_training" placeholder="Name of Training"></b-form-input>
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                      </b-input-group>
                                    </div>
                                  </ValidationProvider>
                                </b-col>
                                <b-col v-if="form_data.training_format == 'internal'">
                                  <ValidationProvider name="Number of modules" rules="required|min_value:1" v-slot="{ valid, errors }">
                                    <div class="required-field">
                                      <label>Number of modules</label>
                                      <b-form-input type="text" v-model="number_of_module" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </div>
                                  </ValidationProvider>
                                </b-col>
                                <b-col>
                                  <functRelevenceField :key="rerender"></functRelevenceField>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row v-if="form_data.training_format">
                          <b-col>
                            <div class="def-func-input-group md-screen-width">
                              <div class="def-func-input-group-col width-md-12 width-3">
                                <b-form-checkbox v-model="form_data.is_recurrence_ck" value="false" unchecked-value="true">Recurrence</b-form-checkbox>
                                <b-row class="recurrence-row width-md-12">
                                  <b-col class=" pl-0 recurrence-select">
                                    <RequiredStaticDropdown :key="rerender" :name="'rec_time'" :rules="'required'" :options="recurrences_data_time"
                                      :error_message="'time of recurrences'" :disabled_toggle="form_data.is_recurrence_ck"></RequiredStaticDropdown>
                                  </b-col>
                                  <b-col class="pl-0 recurrence-select">
                                    <RequiredStaticDropdown :key="rerender" :name="'rec_year'" :rules="'required'" :options="recurrences_data_year"
                                      :error_message="'year of recurrences'" :disabled_toggle="form_data.is_recurrence_ck"></RequiredStaticDropdown>
                                  </b-col>
                                </b-row>
                              </div>
                              <div v-if="form_data.training_format == 'internal'">
                                <div class="def-func-input-group-col width-md-6 width-3 pr-3">
                                  <ValidationProvider name="Type" rules="required" v-slot="{ valid, errors }">
                                    <div class="required-field">
                                      <label>Type</label>
                                      <b-form-select name="rec_type" v-model="form_data.recurrences_type" aria-describedby="rec_type"
                                        :options="recurrences_data_type" :state="errors[0] ? false : (valid ? true : null)"></b-form-select>
                                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </div>
                                  </ValidationProvider>
                                </div>
                                <div class="def-func-input-group-col width-md-6 width-3 pl-3">
                                  <div class="required-field"><label>Time to complete (Days)</label></div>
                                  <RequiredNumberField :key="rerender" name="Time to complete" placeholder="Time to Complete"
                                    rules="required" error_message="Time to complete"></RequiredNumberField>
                                </div>
                                <div class="def-func-input-group-col width-md-12 pl-md-0 pl-xl-5 width-5">
                                  <ValidationProvider name="Format" rules="required" v-slot="{ valid, errors }">
                                    <div class="required-field">
                                      <b-form-group label="Format" class="global-freq mb-0 set-training">
                                        <b-form-radio-group id="radio-group-2" class="add-media-inner" v-model="format"
                                        :state="errors[0] ? false : (valid ? true : null)" :options="formatOptions"></b-form-radio-group>
                                      </b-form-group>
                                      <b-form-invalid-feedback style="display: block">{{ errors[0] }}</b-form-invalid-feedback>
                                    </div>
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div v-if="form_data.training_format == 'external'">
                                <div class="def-func-input-group-col width-md-6 width-3 pr-3">
                                  <label>Time to complete (min)</label>
                                  <b-input-group>
                                    <b-form-input v-model="time_of_completion" name="complete" placeholder="Time to complete (min)"
                                      @keypress="isNumber($event)" aria-describedby="functional_relevance_error"></b-form-input>
                                  </b-input-group>
                                </div>
                                <div class="def-func-input-group-col width-md-6 width-3 pl-3">
                                  <ValidationProvider name="Category" rules="required" v-slot="{ valid, errors }">
                                    <div class="required-field">
                                      <label>Category</label>
                                      <b-form-select name="category" v-model="form_data.category" aria-describedby="category"
                                        :options="categoryOptions" :state="errors[0] ? false : (valid ? true : null)"></b-form-select>
                                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </div>
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div v-if="form_data.training_format == 'classroom'">
                                <div class="def-func-input-group-col width-md-6 width-3 pr-3">
                                  <label>Duration of Course (min)</label>
                                  <b-input-group>
                                    <b-form-input v-model="time_of_completion" name="Duration" placeholder="Duration of Course (min)"
                                      @keypress="isNumber($event)" aria-describedby="functional_relevance_error"></b-form-input>
                                  </b-input-group>
                                </div>
                                <div class="def-func-input-group-col width-md-6 width-3 pl-3">
                                  <ValidationProvider name="Conducted on" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group id="conducted-on" label="Conducted on" label-for="conducted-on" class="required-field">
                                      <b-input-group class="date-field">
                                        <b-input-group-append is-text>
                                          <font-awesome-icon :icon="['far', 'calendar-alt']" />
                                        </b-input-group-append>
                                        <Datepicker v-model="form_data.conducted_on"></Datepicker>
                                      </b-input-group>
                                      <b-form-invalid-feedback style="display:block">{{ errors[0] }}</b-form-invalid-feedback>
                                      <input v-show="false" id="conducted_on" name="conducted-on" :state="errors[0] ? false : ( valid ? true : null )"
                                        v-model="form_data.conducted_on" aria-describedby="conducted_on_error" />
                                    </b-form-group>
                                  </ValidationProvider>
                                </div>
                              </div>
                              <div v-if="form_data.training_format == 'internal'" class="def-func-input-group-col global-checklist-add width-md-12 width-1">
                                <div class="def-func-input-group-col btn-add-outer">
                                  <b-button :disabled="!number_of_module || !modbtn" variant="light" class="btn add-btn" @click="showModule">+</b-button>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="clearfix"></div>
                      <transition name="slide" v-if="form_data.training_format == 'internal'">
                        <div v-if="showModuleOuter" v-bind:class="{ 'moduleOpen': showModuleOuter }">
                          <div class="brief-rounded-sectn set-training-sectn">
                            <b-row><b-col><label class="distribute-group-label">Setup Modules (PPS/PPT/PDF)</label></b-col></b-row>
                            <div class="def-func-input-group mb-0">
                              <b-container>
                                <div v-for="(modules,index) in form_data.module_storage" :key="'modules_'+index" class="training-module">
                                  <b-row>
                                    <b-col>
                                      <div class="def-func-input-group global-checklist-freq training-module-title">
                                        <label class="global-freq freq-resp-title">Module {{index + 1}}</label>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col md="7" xl="3" class="mb-4">
                                      <ValidationProvider rules="required" v-slot="{ valid, errors }">
                                        <b-input-group>
                                          <b-form-input :state="errors[0] ? false : (valid ? true : null)"
                                            placeholder="Name of Module *" v-model="modules.name"></b-form-input>
                                        </b-input-group>
                                        <p class="feedback-error" v-if="errors[0]">This field is required</p>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col md="5" xl="2" class="mb-4">
                                      <ValidationProvider :name="'completion time '+(index+1)" rules="required" v-slot="{ valid, errors }">
                                        <b-input-group>
                                          <b-form-input v-model="modules.completion_time" id="functional_relevance" name="example-input-2" placeholder="Time to complete (in Mins) *"
                                            @keypress="isNumber($event)" :state="errors[0] ? false : (valid ? true : null)" aria-describedby="functional_relevance_error"></b-form-input>
                                        </b-input-group>
                                        <p class="feedback-error" v-if="errors[0]">{{ errors[0] }}</p>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col md="12" xl="7" class="mb-4">
                                      <div class="justify-content-space-between">
                                        <div class="width-100">
                                          <ValidationProvider :name="'file upload '+(index+1)" rules="required|ext:ppt,pptx,ppt,pdf,pps,ppsx" v-slot="{ valid, errors }">
                                            <b-form-file v-model="form_data.module_storage[index].file" class="train-file-upload" :state="errors[0] ? false : (valid ? true : null)"
                                              placeholder="Click the browse button to add a PPS/PPT/PDF file *" drop-placeholder="Drop file here..."></b-form-file>
                                            <p class="feedback-error" v-if="errors[0]">{{ errors[0] | custom_message }}</p>
                                          </ValidationProvider>
                                        </div>
                                        <div class="pl-2 pt-2">
                                          <b-icon v-b-tooltip.hover.top="'Only pdf, ppt, pps is allowed '" icon="info" class="info-icon-size"></b-icon>
                                        </div>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-container>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <!-- <div class="def-func-input-group-col float-right btn-rename-delete">
                            <button type="submit" variant="light" class="btn">Finalize</button>
                          </div> -->
                        </div>
                      </transition>
                      <transition name="slide" v-if="form_data.training_format == 'external'">
                        <div class="moduleOpen">
                          <div class="brief-rounded-sectn set-training-sectn">
                            <b-row>
                              <b-col>
                                <label class="distribute-group-label">Setup External Website Link</label>
                              </b-col>
                            </b-row>
                            <div class="def-func-input-group mb-0">
                              <b-container>
                                <div class="training-module">
                                  <b-row>
                                    <b-col>
                                      <!-- <div class="def-func-input-group global-checklist-freq training-module-title">
                                        <label class="global-freq freq-resp-title">Module {{index + 1}}</label>
                                        </div> http(s)?://([\w-]+\.)+[\w-]+(/[\w- ./?%&amp;=]*)? -->
                                      <!-- /^(http(s)?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi  -->
                                      <div class="def-func-input-group mt-4">
                                        <ValidationProvider name="Website Link" :rules='{ regex: validURL, required: true }' v-slot="{ valid, errors }">
                                          <b-row>
                                            <b-col md="9">
                                              <div class="required-field"><label>Website Link</label></div>
                                              <b-form-input v-model="form_data.external_link" placeholder="Website Link" :state="errors[0] ? false : ( valid ? true : null )" />
                                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                            </b-col>
                                            <b-col md="3">
                                              <div class="def-func-input-group-col btn-rename-delete">
                                                <a :href="form_data.external_link" target="_blank">
                                                  <b-button :disabled="!form_data.external_link || !valid" variant="light" class="btn mt-4">Test URL</b-button>
                                                </a>
                                              </div>
                                            </b-col>
                                          </b-row>
                                        </ValidationProvider>
                                      </div>
                                      <div class="def-func-input-group mt-4">
                                        <b-row>
                                          <b-col md="12" class="issue-editor">
                                            <ValidationProvider name="User Instructions" rules="required" v-slot="{ valid, errors }">
                                              <div class="required-field">
                                                <label>User Instructions</label>
                                              </div>
                                              <b-form-textarea class="textarea-no-resize" no-resize :state="errors[0] ? false : ( valid ? true : null )"
                                                placeholder="User Instructions" rows="7" max-rows="10" v-model="form_data.user_instructions"></b-form-textarea>
                                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                            </ValidationProvider>
                                          </b-col>
                                        </b-row>
                                      </div>
                                      <div class="def-func-input-group mt-4">
                                        <b-row>
                                          <b-col md="12">
                                            <!-- <ValidationProvider name="Training Topics" rules="required" v-slot="{ valid, errors }"> -->
                                              <!-- <div class="required-field"></div> -->
                                              <label>Enter Training Topics For Certificate</label>
                                              <b-form-input placeholder="Training Topics" v-model="form_data.topics" />
                                              <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> :state="errors[0] ? false : ( valid ? true : null )" -->
                                            <!-- </ValidationProvider> -->
                                          </b-col>
                                        </b-row>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-container>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <!-- <div class="def-func-input-group-col float-right btn-rename-delete">
                            <button type="submit" variant="light" class="btn">Finalize</button>
                          </div> -->
                        </div>
                      </transition>
                      <transition name="slide" v-if="form_data.training_format == 'classroom'">
                        <div class="moduleOpen">
                          <div class="brief-rounded-sectn set-training-sectn">
                            <b-row>
                              <b-col>
                                <label class="distribute-group-label">Setup External Classroom Topic</label>
                              </b-col>
                            </b-row>
                            <div class="def-func-input-group mb-0">
                              <b-container>
                                <div class="training-module">
                                  <b-row>
                                    <b-col>
                                      <div class="def-func-input-group mt-4">
                                        <b-row>
                                          <b-col md="12">
                                            <!-- <ValidationProvider name="Training Topics" rules="required" v-slot="{ valid, errors }"> -->
                                              <!-- <div class="required-field"></div> -->
                                              <label>Enter Training Topics For Certificate</label>
                                              <b-form-input placeholder="Training Topics" v-model="form_data.topics" />
                                              <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> -->
                                            <!-- </ValidationProvider> -->
                                          </b-col>
                                        </b-row>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-container>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </transition>
                      <div v-if="showModuleOuter && form_data.training_format == 'internal'" class="def-func-input-group-col float-right btn-rename-delete">
                        <button type="submit" variant="light" class="btn">Finalize</button>
                      </div>
                      <div v-else-if="form_data.training_format && form_data.training_format != 'internal'" class="def-func-input-group-col float-right btn-rename-delete">
                        <button type="submit" variant="light" class="btn">Finalize</button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
  import Datepicker from "vuejs-datepicker";
  import functRelevenceField from "../../components/admin_layouts/dashboard_layouts/fields/functional_relevence";
  import RequiredStaticDropdown from "../../components/admin_layouts/dashboard_layouts/fields/static_required_dropdown_field";
  import RequiredNumberField from "../../components/admin_layouts/dashboard_layouts/fields/static_required_numbers_field";
  import { HTTP_imageupload, HTTP_formdata, HTTP_BASE } from "../../constants/http-common";
  import { isloggedin } from "../../constants/app-constants";
  import { EventBus } from "../../main";
  function initialState() {
    return {
      rerender: 0,
      time_of_completion: "",
      format: "downloadable",
      number_of_module: 1,
      showModuleOuter: false,
      recurrences_data_time: [
        { text: "Select", value: null, disabled: true }
      ],
      recurrences_data_year: [
        { text: "Select", value: null, disabled: true },
        { text: "Year(s)", value: "year" },
        { text: "Month(s)", value: "month" }
      ],
      recurrences_data_type: [
        { text: "Select", value: null, disabled: true },
        { text: "Test Included", value: "Test Included" },
        { text: "Info Only", value: "Info Only" }
      ],
      allow_total_and_passing_fields: false,
      form_data: {
        name_of_training: "",
        functional_relevance: "",
        is_recurrence_ck: true,
        recurrences_times: null,
        recurrences_year: null,
        recurrences_type: null,
        total_point: "",
        passing_score: "",
        module_storage: [],
        training_format: null,
        conducted_on: "",
        external_link: "",
        user_instructions: "",
        topics: "",
        category: null
      },
      upload_Image_storage: [],
      trainingid: "",
      form_status_box:[],
      messsage_loader: false,
      modbtn: true,
      formatOptions: [
        { text: 'Download file and review content offline', value: 'downloadable' },
        { text: 'Web based training', value: 'web' }
      ],
      trainingFormatOptions: [
        { text: 'Training Content On Shoptalk', value: 'internal' },
        // { text: 'External Web Based Training', value: 'external' },
        { text: 'External Classroom Training', value: 'classroom' }
      ],
      categoryOptions: [
        { text: "Select", value: null, disabled: true }
      ],
      validURL: ""
    };
  }
  export default {
    data() {
      return initialState();
    },
    filters: {
      custom_message: function(value) {
        if ( value.includes( "required" ) ) {
          return "This field is required"
        } else if ( value.includes( "field is not a valid file" ) ) {
          return "The uploaded file is not a valid file"
        }
      }
    },
    watch: {
      "form_data.is_recurrence_ck"( newQuery ) {
        this.form_data.is_recurrence_ck = eval(newQuery);
        EventBus.$emit( "clearData" )
        this.form_data.recurrences_times = null;
        this.form_data.recurrences_year = null;
      },
      number_of_module(val) {
        if ( val <= 0 ) {
          this.modbtn = false;
        } else if ( isNaN( Number( val ) ) == true || val % 1 !== 0 || val.includes(".") ) {
          this.modbtn = false;
        } else {
          this.modbtn = true;
        }
      },
      "form_data.training_format"( val ) {
        this.rerender = 0;
        this.time_of_completion = "";
        this.format = "downloadable";
        this.number_of_module = "1";
        this.showModuleOuter = false;
        this.allow_total_and_passing_fields = false;
        this.form_data.recurrences_type = null;
        this.form_data.module_storage = [];
        this.form_data.conducted_on = "";
        this.form_data.external_link = "";
        this.form_data.user_instructions = "";
        this.form_data.topics = "";
        this.form_data.category = null;
        this.modbtn = true;
        this.$nextTick(() => {
          if (this.$refs.observerTrainings) {
            this.$refs.observerTrainings.reset();
          }
        });
      }
    },
    components: {
      functRelevenceField,
      RequiredStaticDropdown,
      RequiredNumberField,
      Datepicker
    },
    methods: {
      resetComponent() {
        this.rerender +=1;
        return null
      },
      async onSubmit() {
        this.messsage_loader = true;
        await this.save_training_data();
        if ( this.form_data.training_format == 'internal' ) {
          await this.save_module();
          for(let i = 0 ; i < this.upload_Image_storage.length;i++){
            await this.upload_image(this.upload_Image_storage[i].trainingId,this.upload_Image_storage[i].file)
          }
        }
        if (this.check_success_fail()) {
          this.messsage_loader = false;
          let response = {
            popup: true,
            msg: "Training Created Successfully"
          }
          EventBus.$emit("success_resp", response)
          await this.resetComponent();
          Object.assign( this.$data, initialState() );
          this.initialFunction();
          this.$nextTick(() => {
            if (this.$refs.observerTrainings) {
              this.$refs.observerTrainings.reset();
            }
          });
          if ( isloggedin.access_rights.includes('List Trainings') )
            this.$router.push({ path: "/list_of_trainings" });
        } else {
          this.messsage_loader = false;
          let response = {
            popup: true,
            msg: "Failed to create Trainings"
          }
          EventBus.$emit("error_resp", response)
        }
      },
      async save_training_data() {
        let training_form_data = {
          title: this.form_data.name_of_training,
          for_id: this.form_data.functional_relevance,
          recurrence: {
            number: this.form_data.recurrences_times,
            time: this.form_data.recurrences_year
          },
          type: this.form_data.recurrences_type,
          time_to_complete: this.time_of_completion,
          format: this.format,
          training_format: this.form_data.training_format,
          external_link: this.form_data.external_link,
          user_instructions: this.form_data.user_instructions,
          topics: this.form_data.topics,
          conducted_on: this.form_data.conducted_on,
          category: this.form_data.category
        };
        await HTTP_formdata.post("training/add_training", training_form_data)
          .then(response => {
            if (response.data.code == "200") {
              this.form_status_box.push({ type: "training Data", status: true })
              this.trainingid = response.data.training;
            } else {
              this.form_status_box.push({ type: "training Data", status: false })
            }
          })
          .catch(error => {
            this.form_status_box.push({ type: "training Data", status: false })
          });
      },
      async save_module() {
        for (let i = 0; i < this.form_data.module_storage.length; i++) {
          let form_data = {
            title: this.form_data.module_storage[i].name,
            study_time_req: this.form_data.module_storage[i].completion_time,
            training: this.trainingid
          };
          await HTTP_formdata.post("training/add_modules", form_data)
            .then(response => {
              if (response.data.code == "200") {
                this.form_status_box.push({ type: "save module " + (i+1), status: true })
                this.upload_Image_storage.push({ trainingId: response.data.training, file: this.form_data.module_storage[i].file })
              } else {
                this.form_status_box.push({ type: "save module " + (i+1), status: false })
              }
            })
            .catch(error => {
              this.form_status_box.push({ type: "save module " + (i+1), status: false })
            });
        }
      },
      async upload_image(module_id, image_file) {
        let file_data = new FormData();
        file_data.set("moduleFile", image_file);
        await HTTP_imageupload.post(
          "training/modules_file/" + module_id,
          file_data
        )
          .then(Response => {
            if (Response.data.code == 200) {
              this.form_status_box.push({type:"file :"+image_file.name,status:true})
            } else {
              this.form_status_box.push({type:"file :"+image_file.name,status:false})
            }
          })
          .catch(error => {
            this.form_status_box.push({type:"file :"+image_file.name,status:false})
          });
      },
      check_success_fail() {
        for (let i = 0; i < this.form_status_box.length; i++) {
          if (this.form_status_box[i].status == false) {
            return false;
          }
        }
        return true;
      },
      isNumber: function(evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      showModule() {
        this.form_data.module_storage = [];
        for (let i = 0; i < this.number_of_module; i++) {
          this.form_data.module_storage.push({
            name: "",
            completion_time: "",
            file: []
          });
        }
        this.showModuleOuter = true;
      },
      generate_recurrence() {
        this.recurrences_data_time = [
          { text: "Select", value: null, disabled: true }
        ];
        for (let i = 1; i <= 18; i++) {
          this.recurrences_data_time.push({ text: i, value: i });
        }
      },
      listCategory() {
        HTTP_BASE.get( "training_categories" )
          .then(response => {
            for (let i = 0; i < response.data['hydra:member'].length; i++)
              this.categoryOptions.push({ text: response.data['hydra:member'][i].category, value: response.data['hydra:member'][i].public_id });
          })
          .catch(err => {});
      },
      initialFunction() {
        this.generate_recurrence();
        this.listCategory();
        this.validURL = '^(https?:\\/\\/)'+ //? protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i';
      }
    },
    created() {
      EventBus.$on("send_static_dropdown_value", data => {
        if (data.name == "rec_time") {
          this.form_data.recurrences_times = data.data_val;
        }
        if (data.name == "rec_year") {
          this.form_data.recurrences_year = data.data_val;
        }
        if (data.name == "rec_type") {
          this.form_data.recurrences_type = data.data_val;
          if (data.data_val == "Info Only") {
            this.allow_total_and_passing_fields = true;
          } else {
            this.allow_total_and_passing_fields = false;
          }
        }
      });
      EventBus.$on("static-required-number-field", send_data => {
        if (send_data.name == "total_points") {
          this.form_data.total_point = data.data_val;
        }
        if (send_data.name == "passing_score") {
          this.form_data.passing_score = data.data_val;
        }
        if (send_data.name == "Time to complete") {
          this.time_of_completion = send_data.data_val;
        }
      });
      EventBus.$on("send-functional-relevance-data", functional_relevance => {
        this.form_data.functional_relevance = functional_relevance;
      });
    },
    mounted: function() {
      this.initialFunction();
    }
  };
</script>
<style>
  @import "./def_function.css";
  .training-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: clamp(20px, 17%, 450px)
  }
</style>
