var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-outer forgot-outer"},[_c('img',{staticClass:"login-logo",attrs:{"src":_vm.shopTalkImg}}),_c('h2',{staticClass:"login-title"},[_vm._v("Forgot Password?")]),_c('div',{staticClass:"forgot-tabs"},[_c('p',{staticClass:"tab-note"},[_vm._v("If your username is an email address then enter the Email address below. ")]),_c('b-tabs',{attrs:{"active-tab-class":"","content-class":"mt-3","align":"center"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":"Email","active":""}},[_c('ValidationObserver',{ref:"observerEmail",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{staticClass:"login-form text-left",on:{"submit":function($event){$event.preventDefault();return passes( _vm.onSubmit )}}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mb-4"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{attrs:{"icon":"person-fill"}})],1),_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Email"},model:{value:(_vm.forgot_password.username),callback:function ($$v) {_vm.$set(_vm.forgot_password, "username", $$v)},expression:"forgot_password.username"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"text-center"},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.messsage_loader),expression:"messsage_loader"}],attrs:{"variant":"primary","label":"Spinning"}})],1),_c('b-alert',{attrs:{"variant":"success"},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}},[_vm._v(_vm._s(_vm.message))]),_c('b-alert',{attrs:{"variant":"danger"},model:{value:(_vm.fail),callback:function ($$v) {_vm.fail=$$v},expression:"fail"}},[_vm._v(_vm._s(_vm.message))]),_c('b-button',{staticClass:"login-submit",attrs:{"type":"submit","variant":"light"}},[_vm._v("Submit")]),_c('div',{staticClass:"clearfix"}),_c('p',{staticClass:"text-center"},[_c('router-link',{staticClass:"mb-5 login-forgot",attrs:{"to":"/login"}},[_vm._v("Login")])],1)],1)]}}])})],1),_c('b-tab',{attrs:{"title":"Non-Email","disabled":""}},[_c('ValidationObserver',{ref:"observerNonEmail",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{staticClass:"login-form text-left",on:{"submit":function($event){$event.preventDefault();return passes( _vm.onSubmit )}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"non-email-radio"},[_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : (valid ? true : null),"id":"radio-group-nonemail","options":_vm.nonEmailOptions,"name":"know-username"},on:{"input":_vm.nonEmailRadioChange},model:{value:(_vm.userdetails),callback:function ($$v) {_vm.userdetails=$$v},expression:"userdetails"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.userdetails == 'know-userdetails')?_c('ValidationProvider',{attrs:{"name":"Username","rules":("" + ((_vm.userdetails == 'know-userdetails') ? 'required|alpha_num' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mb-4"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{attrs:{"icon":"person-fill"}})],1),_c('b-form-input',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"placeholder":"Username"},model:{value:(_vm.forgot_password.username),callback:function ($$v) {_vm.$set(_vm.forgot_password, "username", $$v)},expression:"forgot_password.username"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"text-center mt-3"},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.messsage_loader),expression:"messsage_loader"}],attrs:{"variant":"primary","label":"Spinning"}})],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.nonUsernameMsg),expression:"nonUsernameMsg"}],staticClass:"tab-note text-center non-username-note"},[_vm._v("Unfortunately, we cannot reset your password."),_c('br'),_vm._v("Please contact your station manager to reset your password.")]),_c('b-alert',{attrs:{"variant":"success"},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}},[_vm._v(_vm._s(_vm.message))]),_c('b-alert',{attrs:{"variant":"danger"},model:{value:(_vm.fail),callback:function ($$v) {_vm.fail=$$v},expression:"fail"}},[_vm._v(_vm._s(_vm.message))]),(_vm.userdetails != 'no-userdetails')?_c('b-button',{staticClass:"login-submit",attrs:{"type":"submit","variant":"light"}},[_vm._v("Submit")]):_vm._e(),_c('router-link',{staticClass:"mb-5 login-forgot tab2-login-link",attrs:{"to":"/login"}},[_vm._v("Login")])],1)]}}])})],1)],1)],1),_c('p',{staticClass:"powered-by"},[_vm._v("POWERED BY")]),_c('img',{staticClass:"poweredby-logo",attrs:{"src":_vm.poweredByImg}})])}
var staticRenderFns = []

export { render, staticRenderFns }