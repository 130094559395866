<template>
  <div>
    <div class="content-outer">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="def-function-outer content-left">
              <validation-observer ref="observer" v-slot="{ passes }">
                <b-form class="input-padding breifing-content" @submit.prevent="passes(onSubmit)">
                  <div class="def-func-form">
                    <div class="def-func-input-form-group">
                      <div class="def-func-input-group">
                        <b-row>
                          <b-col class="def-func-input-group-col" sm="6" lg="6" xl="4">
                            <validation-provider :name="constants.BRIEFING_TITLE_VALIDATION" rules="required" v-slot="validationContext">
                              <div class="required-field">
                                <label for="input-1">{{constants.BRIEFING_TITLE_LABEL}}</label>
                                <b-input-group class="mb-4">
                                  <b-form-input
                                    id="input-1"
                                    :state="getValidationState(validationContext)"
                                    v-model="form_data.briefing_title"
                                    :placeholder="constants.BRIEFING_TITLE_PLACEHOLDER"
                                  ></b-form-input>
                                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                              </div>
                            </validation-provider>
                          </b-col>
                          <b-col class="def-func-input-group-col" sm="6" lg="6" xl="2">
                            <validation-provider
                              :name="constants.FUNCTIONAL_RELEVENCE_LABEL"
                              :rules="{ required: true }"
                              v-slot="validationContext"
                            >
                                <b-form-group
                                  id="functional-relevance"
                                  :label="constants.FUNCTIONAL_RELEVENCE_VALIDATION"
                                  label-for="functional_relevance"
                                  class="required-field"
                                >
                                  <b-form-select
                                    id="functional_relevance"
                                    name="example-input-2"
                                    v-model="form_data.functional_relevance"
                                    :options="functions"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="functional_relevance_error"
                                  ></b-form-select>
                                  <b-form-invalid-feedback id="functional_relevance_error">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col class="def-func-input-group-col" sm="4" lg="6" xl="2">
                            <validation-provider
                              :name="constants.TYPE_OF_BRIEFINGS_LABEL"
                              :rules="{ required: true }"
                              v-slot="validationContext"
                            >
                                <b-form-group
                                  id="type-of-briefing"
                                  :label="constants.TYPE_OF_BRIEFINGS_VALIDATIONL"
                                  label-for="type_of_briefing"
                                  class="required-field"
                                >
                                  <b-form-select
                                    id="type_of_briefing"
                                    name="example-input-2"
                                    v-model="form_data.briefing_type"
                                    :options="type"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="type_of_briefing_error"
                                  ></b-form-select>
                                  <b-form-invalid-feedback id="type_of_briefing_error">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col class="def-func-input-group-col" sm="4" lg="3" xl="2">
                            <validation-provider
                              :name="constants.EFFECTIVE_DATE_VALIDATION"
                              :rules="{ required: true }"
                              v-slot="validationContext"
                            >
                              <div class="required-field">
                                <b-form-group
                                  id="effective-Date"
                                  :label="constants.EFFECTIVE_DATE_LABEL"
                                  label-for="Effective_Date"
                                  class="required-field"
                                >
                                  <b-input-group class="date-field" >
                                    <b-input-group-append is-text>
                                      <font-awesome-icon :icon="['far', 'calendar-alt']" />
                                    </b-input-group-append>
                                    <Datepicker
                                      v-model="form_data.start_date"
                                      :disabled-dates="stateStart.disabledDates"
                                      v-bind:class="{ isInvalid : validationContext.errors[0] }"
                                    ></Datepicker>
                                  </b-input-group>
                                  <b-form-select
                                    v-show="false"
                                    id="Effective_Date"
                                    name="example-input-2"
                                    v-model="form_data.start_date"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="Effective_Date_error"
                                  ></b-form-select>
                                  <b-form-invalid-feedback id="Effective_Date_error">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                              </div>
                            </validation-provider>
                          </b-col>
                          <b-col class="def-func-input-group-col" sm="4" lg="3" xl="2">
                            <validation-provider
                              :name="constants.END_DATE_VALIDATION"
                              v-slot="validationContext"
                            >
                              <b-form-group id="end-date" :label="constants.END_DATE_LABEL" label-for="End_Date">
                                <b-input-group class="date-field">
                                  <b-input-group-append is-text>
                                    <font-awesome-icon :icon="['far', 'calendar-alt']" />
                                  </b-input-group-append>
                                  <Datepicker clear-button
                                    v-model="form_data.end_date"
                                    :disabled-dates="stateEnd.disabledDates"
                                  ></Datepicker>
                                </b-input-group>
                                <b-form-select
                                  v-show="false"
                                  id="End_Date"
                                  name="example-input-2"
                                  v-model="form_data.end_date"
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="End_Date_error"
                                ></b-form-select>
                                <b-form-invalid-feedback id="End_Date_error">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col class="def-func-input-group-col" sm="12">
                            <validation-provider
                              :name="constants.BRIEFINGS_DETAIL_VALIDATION"
                              :rules="{ required: true }"
                              v-slot="validationContext"
                            >
                              <b-form-group
                                id="briefing-details"
                                :label="constants.BRIEFINGS_DETAIL_LABEL"
                                label-for="briefing_details" class="required-field"
                              >
                                  <b-form-textarea
                                    id="briefing_details"
                                    :placeholder="constants.BRIEFINGS_DETAIL_PLACEHOLDER"
                                    rows="6"
                                    no-resize
                                    name="example-input-2"
                                    v-model="form_data.briefing_details"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="briefing_details_error"
                                  ></b-form-textarea>
                                  <b-form-invalid-feedback id="briefing_details_error">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <div class="clearfix"></div>
                        <b-row>
                          <b-col class="def-func-input-group-col" sm="12" lg="6">
                            <validation-provider
                              :name="constants.BULLET_POINTS_VALIDATION"
                              v-slot="validationContext"
                            >
                              <b-form-group
                                id="bullet-points"
                                :label="constants.BULLET_POINTS_LABEL"
                                label-for="bullet_points"
                              >
                                <ckeditor
                                  :editor="editor"
                                  v-model="form_data.buller_point"
                                  :config="editorConfig"
                                ></ckeditor>
                                <b-form-input
                                  v-show="false"
                                  id="bullet_points"
                                  name="example-input-2"
                                  v-model="form_data.buller_point"
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="bullet_points_error"
                                ></b-form-input>
                                <b-form-invalid-feedback id="bullet_points_error">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col class="def-func-input-group-col" sm="12" lg="6">
                            <validation-provider
                              :name="constants.IMAGE_OR_VIDEO_VALIDATION"
                              v-slot="validationContext"
                            >
                              <b-form-radio-group
                                id="radio-group-2" :disabled="uploadPercentage > 0"
                                v-model="form_data.upload_type"
                                name="add-media-type"
                                class="add-media-inner"
                                :state="getValidationState(validationContext)"
                              >
                              <!-- @change="mediaOptionChange($event)" -->
                                <b-form-radio :value="constants.PICTURE_RADIO_VALUE">{{constants.PICTURE_RADIO_LABEL}}</b-form-radio>
                                <b-form-radio :value="constants.VIDEO_RADIO_VALUE">{{constants.VIDEO_RADIO_LABEL}}</b-form-radio>
                                <b-form-invalid-feedback id="Upload_Type_error">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                              </b-form-radio-group>
                            </validation-provider>
                            <div class="clearfix"></div>
                            <div class="add-files-outer slide-brief">
                              <div
                                class="slide-outer"
                                :class="{'slide-open': form_data.upload_type == constants.PICTURE_RADIO_VALUE ? true : false}"
                              >
                                <b-form-group class="no-border slide-inner">
                                  <b-form-radio-group :disabled="form_data.upload_type == constants.PICTURE_RADIO_VALUE ? false : true"
                                    id="radio-group-2"
                                    v-model="selected"
                                    name="add-media-file"
                                    class="add-media-inner"
                                    value="picture"
                                  >
                                    <label>{{constants.ADD_NO_OF_PICTURE_LABEL_SELECT}}</label>
                                    <b-form-radio v-model="form_data.number_of_pictures" value="1">1</b-form-radio>
                                    <b-form-radio v-model="form_data.number_of_pictures" value="2">2</b-form-radio>
                                    <b-form-radio v-model="form_data.number_of_pictures" value="4">4</b-form-radio>
                                  </b-form-radio-group>
                                </b-form-group>
                              </div>
                            </div>
                            <div class="add-media-content">
                              <div v-if="form_data.upload_type == constants.PICTURE_RADIO_VALUE" class="images-outer">
                                <div v-for="(no_of_images, index) in parseInt( form_data.number_of_pictures )" class="images-inner media-inner" :class="`base-image-${form_data.number_of_pictures}`" :key="index">
                                  <img class="base-image-input" :src="form_data.images[index].display_name ? form_data.images[index].display_name : imageData" alt @click="chooseImage(index)" />
                                  <div class="justify-content-space-between" style="float: right;">
                                    <div class="mt-4 pt-3 mr-4"><b-icon v-b-tooltip.hover.left="'Only jpg, jpeg or png allowed'" icon="info" class="info-icon-size"></b-icon></div>
                                    <button
                                      @click.prevent="delete_image(index)"
                                      class="base-img-delete"
                                      variant="light"
                                    >
                                      <img src="@/assets/images/delete-base-img.png" title="Delete" />
                                    </button>
                                  </div>
                                </div>
                                <validation-provider
                                  name="Image"
                                  rules="ext:jpg,png,jpeg"
                                  v-slot="validationContext"
                                  ref="image_refrence"
                                >
                                  <b-input-group class="mb-4">
                                    <b-form-file accept="image/jpeg, image/png, .jpg, .png, .jpeg" v-show="false"
                                    id="fileInput" v-model="imgInput" ref="fileInput" @change="image_uploaded($event)"
                                    v-bind:class="{'is-invalid':validationContext.errors[0]}" plain></b-form-file>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                  </b-input-group>
                                </validation-provider>
                              </div>
                              <div v-else-if="form_data.upload_type == constants.VIDEO_RADIO_VALUE" class="pdf-inner media-inner">
                                <div v-if="video_src ==''">
                                  <img src="@/assets/images/video.png" title="Add Video" class="mb-3" />
                                </div>
                                <div v-else>
                                  <video-player class="video-player-box brief-video"
                                    ref="videoPlayer"
                                    :options="playerOptions"
                                    :playsinline="true"
                                    customEventName="customstatechangedeventname" 
                                    @statechanged="playerStateChanged($event)">
                                  </video-player>
                                </div>
                                <div class="justify-content-space-between" style="align-items: flex-end;">
                                  <div class="width-100">
                                    <!-- <validation-provider name="Video" rules="ext:mp4,mov,flv,avi,wm" v-slot="validationContext" ref="video_refrence"> -->
                                      <b-input-group class="mb-4">
                                        <b-form-file class="pdf-btn video-add-btn" accept="video/mp4, video/avi, video/x-flv, video/quicktime, video/x-ms-wmv, .mp4, .mov, .flv, .avi, .wmv"
                                         browse-text="Click to Upload" @change="video_upload($event)" :disabled="uploadPercentage > 0"
                                        ></b-form-file>
                                      </b-input-group>
                                      <!-- v-model="videoInput" v-bind:class="{'is-invalid':validationContext.errors[0]}"-->
                                      <div v-if="uploadPercentage > 0">
                                        <b-progress class="video-progress-bar" :value="uploadPercentage" :max="100" show-progress animated></b-progress>
                                        <div class="upload-percentage"><span v-if="uploadPercentage == 100">Completed</span><span v-else>Uploading {{ uploadPercentage }}% ...</span></div>
                                      </div>
                                      <p class="feedback-error" v-if="videoValidError">{{ videoValidError }}</p>
                                      <!-- <b-form-invalid-feedback style="display: block">{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                                    <!-- </validation-provider> -->
                                  </div>
                                  <div class="mb-2 ml-2 pb-1"><b-icon v-b-tooltip.hover.top="'Only MP4 is allowed. Maximum size allowed is 1GB. File name must not contain any spaces or special characters with the expection of \'_\' , \'-\' and \'.\''" icon="info" class="info-icon-size"></b-icon></div>
                                </div>
                                <!-- <div class="mt-3">{{ Mediafile ? Mediafile.name : '' }}</div> -->
                              </div>
                            </div>
                            <div class="add-pdf-btn">
                              <validation-provider
                                :name="constants.UPLOAD_PDF_VALIDATION"
                                rules="ext:pdf"
                                v-slot="validationContext"
                                ref="provider"
                              >
                                <b-form-group>
                                  <b-form-file
                                    class="pdf-btn"
                                    id="pdfInput"
                                    v-bind:class="{'is-invalid':validationContext.errors[0]}"
                                    accept="application/pdf"
                                    browse-text="Add Attachments"
                                    @change="file_upload($event)"
                                  />
                                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                              <div v-for="(docfiles, index) in form_data.doc_files" :key="index" class="added-pdf-outer">
                                <img class="added-pdf" src="@/assets/images/add-pdf.png" v-if="form_data.doc_files[index].file_name" />
                                <button class="brief-pdf-delete" v-if="form_data.doc_files[index].file_name" @click.prevent="delete_file(index)" variant="light"></button>
                                <p>{{ form_data.doc_files[index].file_name }}</p>
                                <div class="mt-2">{{ Mediafile ? Mediafile.name : "" }}</div>
                              </div>
                              <div class="pb-3" style="width: 27px;"><b-icon v-b-tooltip.hover.top="'Only pdf is allowed'" icon="info" class="info-icon-size"></b-icon></div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                  <div class="briefing-btns">
                    <b-button class="btn" variant="light" @click.prevent="go_to_briefings">{{constants.BACK_TO_BRIEFINGS_BUTTON}}</b-button>
                    <b-button v-b-modal.modal-brief-look @click.prevent class="btn" variant="light" :disabled="uploadPercentage > 0">{{constants.HOW_IT_LOOKS_BUTTON}}</b-button>
                    <b-button @click="traverse=0" type="submit" variant="light" :disabled="uploadPercentage > 0">{{constants.SAVE_BUTTON}}</b-button>
                    <b-button @click="traverse=1" type="submit" variant="light" :disabled="uploadPercentage > 0">{{constants.SAVE_AND_DISTRIBUTE_BUTTON}}</b-button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal
      hide-footer
      id="modal-brief-look"
      size="xl"
      modal-class="flipModal manage-user-modal"
      :title="form_data.briefing_title"
      centered
    >
      <div class="def-func-form">
        <div class="def-func-input-form-group">
          <b-row>
            <b-col>
              <div class="def-func-input-group">
                <div class="distribute-group border-bottom border-default">
                  <b-row class="briefing-admin-panel pt-2">
                    <b-col>
                      <div class>
                        <label class="text-left">
                          {{constants.POPUP_EFFECTIVE}}
                          <span>
                            {{ form_data.start_date | moment }}
                          </span>
                        </label>
                      </div>
                    </b-col>
                    <b-col v-if="form_data.briefing_type == 'Mandatory'">
                      <div class>
                        <label class="text-center">
                          <span class="mandatory-field">{{form_data.briefing_type}}</span>
                        </label>
                      </div>
                    </b-col>
                    <b-col>
                      <div class>
                        <label class="text-right">
                          {{constants.POPUP_ULTIL}}
                          <span>
                            {{ form_data.end_date | moment }}
                          </span>
                        </label>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="distribute-group">
                  <b-row class="briefing-admin-panel pt-2">
                    <b-col sm="12">
                      <label>
                        <span>{{form_data.briefing_details}}</span>
                      </label>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" lg="6">
              <div class="brief-look-cols slider-outer">
                <h6 class="brief-look-title text-center">{{constants.POPUP_KEYNOTE}}</h6>
                <div class="look-popup-text ck-content" v-html="form_data.buller_point"></div>
              </div>
            </b-col>
            <b-col md="12" lg="6">
              <div class="brief-look-cols">
                <h6 class="brief-look-title text-center">{{constants.POPUP_ADDITIONAL_REFERENCE}}</h6>
              </div>
              <div class="brief-look-cols slider-outer">
                <div v-if="form_data.upload_type == constants.PICTURE_RADIO_VALUE">
                  <transition-group name="fade" tag="div" class="slider-inner">
                    <div v-for="number in [currentNumber]" :key="number">
                      <img :src="currentImage" />
                    </div>
                  </transition-group>
                  <a class="prev" @click="prev" href="#">
                    <img src="@/assets/images/btn_startcheck.png" title="Previous" />
                  </a>
                  <a class="next" @click="next" href="#">
                    <img src="@/assets/images/btn_startcheck.png" title="Next" />
                  </a>
                </div>
                <div class="display-briefing-video" v-else-if="form_data.upload_type == constants.VIDEO_RADIO_VALUE && video_src != ''">
                  <video-player class="video-player-box brief-video"
                    ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"
                    customEventName="customstatechangedeventname" 
                    @statechanged="playerStateChanged($event)">
                  </video-player>
                </div>
              </div>
              <div class="brief-look-cols">
                <h6 class="brief-look-title text-center">{{constants.POPUP_ATTACHMENTS}}</h6>
                <vueper-slides
                  ref="myVueperSlides"
                  :visible-slides="3"
                  slide-multiple
                  :gap="3"
                  :slide-ratio="1 / 4"
                  :dragging-distance="200"
                  :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
                  class="pdf-slider no-shadow"
                >
                  <vueper-slide
                    v-for="(slide) in slides"
                    :key="slide.id"
                    :content="slide.content"
                    class="pdf-slides"
                  />
                </vueper-slides>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    <div class="text-center spinner-div overlay" v-show="messsage_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <confirmModal></confirmModal>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Link from '@ckeditor/ckeditor5-link/src/link';
// import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import List from '@ckeditor/ckeditor5-list/src/list';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import play_back from "../../assets/images/play_button.png";
import camera_image from "../../assets/images/camera.png";
import { HTTP, HTTP_imageupload, HTTP_formdata } from "../../constants/http-common";
import { isloggedin, BASE_URL_VER, CREATE_NEW_BRIEFINGS } from "../../constants/app-constants";
import { EventBus } from "../../main";
import moment from "moment";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { videoPlayer } from 'vue-video-player';
import confirmModal from "../../components/admin_layouts/dashboard_layouts/status_message/confirm_resp";
function initialState() {
  return {
    constants: CREATE_NEW_BRIEFINGS,
    traverse: 0,
    slides: [],
    video_src: "",
    form_status_box: [],
    user_uuid: "",
    //video-config
    uploadPercentage: 0,
    video: {
      sources: [ {
        src: "",
        type: "video/mp4"
      } ],
      options: {
        autoplay: false,
        volume: 0.6,
        poster: play_back
      },
      errorMsg: "now working!"
    },
    imageData: camera_image,
    editor: ClassicEditor,
    editorConfig: {
      plugins: [
        Bold,
        Essentials,
        FontColor,
        FontSize,
        Indent,
        Italic,
        Link,
        // AutoLink,
        List,
        ListStyle,
        Paragraph,
        PasteFromOffice,
        TextTransformation,
        Underline
      ],
      toolbar: {
        items: [
          'fontSize', 'fontColor', '|', 'bold', 'italic', 'underline', '|',
          'bulletedList', 'numberedList', '|', 'indent', 'outdent', '|', 'link', '|', 'undo', 'redo'
        ]
      },
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: {
          isExternal: {
            mode: 'automatic',
            callback: url => /^(http?)?/.test( url ),//url.startsWith( 'http' ),/^(https?:)?\/\//.test( url )
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          }
        }
      }
    },
    form_data: {
      briefing_title: "",
      functional_relevance: null,
      briefing_type: null,
      start_date: "",
      end_date: "",
      briefing_details: "",
      buller_point: "",
      number_of_pictures: "1",
      upload_type: CREATE_NEW_BRIEFINGS.PICTURE_RADIO_VALUE,
      images: [
        { image_file: "", display_name: "", filename: "" },
        { image_file: "", display_name: "", filename: "" },
        { image_file: "", display_name: "", filename: "" },
        { image_file: "", display_name: "", filename: "" }
      ],
      doc_files: []
    },
    functions: [
      { text: "Select", value: null , disabled:true},
      { text: "All", value: "all" }
    ],
    type: CREATE_NEW_BRIEFINGS.TYPES_OF_BRIEFINGS_DATA,
    briefingImages: [],
    currentNumber: 0,
    selected: 1,
    Mediafile: null,
    stateStart: {
      disabledDates: {
        to: new Date(),
        from: ""
      }
    },
    stateEnd: {
      disabledDates: {
        to: new Date(),
        from: ""
      }
    },
    imageindex: 0,
    briefingId: '',
    messsage_loader: false,
    playerOptions: {
      // videojs options
      muted: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [{
        type: "video/mp4",
        src: ""
      }]
    },
    imgInput: null,
    videoInput: null,
    modalcomfirm: true,
    video_public_id: "",
    videoValidError: ""
  };
}
export default {
  components: {
    Datepicker,
    VueperSlides,
    VueperSlide,
    videoPlayer,
    confirmModal
  },
  data() {
    return initialState();
  },
  filters: {
    moment: function(value) {
      if (value) {
        return moment(value).format("DD MMM YYYY");
      }
    }
  },
  watch: {
    "form_data.start_date": {
      handler(val) {
        if (this.form_data.start_date) {
          this.stateEnd.disabledDates.to = this.form_data.start_date;
        }
        deep: true;
      }
    },
    "form_data.end_date": {
      handler(val) {
        // if (this.form_data.end_date) {
          this.stateStart.disabledDates.from = this.form_data.end_date;
        // }
        deep: true;
      }
    },
    "form_data.upload_type": {
      handler( newData, oldData ) {
        //this.modalcomfirm == true
        if ( ( newData == this.constants.PICTURE_RADIO_VALUE && this.video_src ) || ( newData == this.constants.VIDEO_RADIO_VALUE && this.briefingImages.length > 0 ) ) {
          this.$bvModal.show('confirm-delete');
          // this.modalcomfirm = true;
          let displayMsg = {
            briefingMediaChange: newData,
            previousOption: oldData
          }
          EventBus.$emit( "deleteSelected", displayMsg );
        }
        this.videoValidError = "";
        deep: true;
      }
    }
  },
  methods: {
    go_to_briefings() {
      this.$router.push({ path: "/list_of_briefings" });
    },
    next: function() {
      this.currentNumber += 1;
    },
    prev: function() {
      this.currentNumber -= 1;
    },
    chooseImage(index) {
      this.imageindex = index;
      this.$refs.fileInput.$el.click();
    },
    async image_uploaded(event) {
      const { valid } = await this.$refs.image_refrence.validate(event);
      if (valid) {
        let index = this.imageindex;
        if (this.form_data.images[index]) {
          this.form_data.images[index].image_file = event.target.files[0];
          this.form_data.images[index].display_name = URL.createObjectURL(
            event.target.files[0]
          );
          this.form_data.images[index].filename = "";
        } else {
          if (parseInt(index) == 3) {
            if (!this.form_data.images[1]) {
              this.form_data.images.push({ image_file: "", display_name: "", filename: "" });
              this.form_data.images.push({ image_file: "", display_name: "", filename: "" });
              this.form_data.images.push({
                image_file: event.target.files[0],
                display_name: URL.createObjectURL(event.target.files[0]), filename: ""
              });
            } else {
              this.form_data.images.push({ image_file: "", display_name: "", filename: "" });
              this.form_data.images.push({
                image_file: event.target.files[0],
                display_name: URL.createObjectURL(event.target.files[0]), filename: ""
              });
            }
          } else {
            this.form_data.images.push({
              image_file: event.target.files[0],
              display_name: URL.createObjectURL(event.target.files[0]), filename: ""
            });
          }
        }
        this.briefingImages = [];
        for (let i = 0; i < this.form_data.images.length; i++) {
          if (this.form_data.images[i].display_name != "") {
            this.briefingImages.push(this.form_data.images[i].display_name);
          }
        }
        document.getElementById('fileInput').value = ''
        this.imgInput = null
      }
    },
    async file_upload(event) {
      const { valid } = await this.$refs.provider.validate(event);
      if (valid) {
        this.slides.push({
          id: this.slides.length + 1,
          title: event.target.files[0].name,
          content: '<img src="' + require('@/assets/images/add-pdf.png') + '" title="Add PDF"/><br><p>' + event.target.files[0].name + "</p>"
        });
        this.form_data.doc_files.push({
          file_data: event.target.files[0],
          file_name: event.target.files[0].name,
          dFile: ""
        });
        document.getElementById('pdfInput').value = '';
      }
    },
    async video_upload(event) {
      // let rg = /^[^()@&\s\+]+$/; // 2147483648 - 2 GB
      let rg = /^[a-zA-Z\d\-_\.]+$/i;
      if ( event.target.files.length > 0 && ( event.target.files[0].size <= 1073741824 ) && ( rg.test( event.target.files[0].name ) ) && ( ( event.target.files[0].name.substring( event.target.files[0].name.length - 4 ) == ".flv" ) || event.target.files[0].type == "video/mp4" || event.target.files[0].type == "mp4" || event.target.files[0].type == "video/avi" || event.target.files[0].type == "avi" || event.target.files[0].type == "video/x-flv" || event.target.files[0].type == "flv" || event.target.files[0].type == "video/quicktime" || event.target.files[0].type == "mov" || event.target.files[0].type == "video/x-ms-wmv" || event.target.files[0].type == "wmv" ) ) {
        this.video.sources[0].src = event.target.files[0];
        this.videoValidError = "";
        // this.video_src = URL.createObjectURL(event.target.files[0]);
        await this.upload_video();
        // this.playerOptions.sources[0].src = URL.createObjectURL(event.target.files[0]);
      } else {
        this.video.sources[0].src = "";
        this.video_src = "";
        this.playerOptions.sources[0].src = "";
        this.video_public_id = "";
        this.videoValidError = 'Select a valid video file';
      }
      // this.videoInput = null
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    delete_image(index) {
      for (let i = 0; i < this.briefingImages.length; i++) {
        if ( this.briefingImages[i] == this.form_data.images[index].display_name ) {
          this.briefingImages.splice(i, 1);
        }
      }
      if ( this.form_data.images[index].image_file == "" && this.form_data.images[index].filename != "" ) {
        HTTP.delete( "briefings/delete_files/" + this.briefingId + "?filename=" + this.form_data.images[index].filename )
          .then(response => {})
          .catch(err => {});
      }
      this.form_data.images[index].image_file = "";
      this.form_data.images[index].display_name = "";
      this.form_data.images[index].filename = "";
    },
    delete_file(index) {
      if ( ! this.form_data.doc_files[index].file_data || this.form_data.doc_files[index].file_data == "" ) {
        HTTP.delete( "briefings/delete_files/" + this.briefingId + "?filename=" + this.form_data.doc_files[index].dFile )
          .then(response => {})
          .catch(err => {});
      }
      this.slides.splice( index, 1 );
      this.form_data.doc_files.splice( index, 1 );
      for ( let a = 0; a < this.slides.length; a++ ) {
        this.slides[a].id = a + 1;
      }
    },
    async onSubmit(e) {
      this.messsage_loader = true;
      await this.save_user_data();
      let uuid = this.user_uuid;
      if ( uuid ) {
        if ( this.form_data.doc_files.length > 0 ) {
          await this.save_upload_data(uuid, "pdf");
        }
        if ( this.form_data.upload_type == this.constants.PICTURE_RADIO_VALUE ) {
          await this.save_upload_data(uuid, "picture");
        } 
        // else {
        //   if ( this.video.sources[0].src ) {
        //     await this.upload_video(uuid);
        //   }
        // }
      }
      if (this.check_success_fail()) {
        this.messsage_loader = false;
        let response = {
          popup:true,
          popupActive:true,
          msg: ( this.briefingId == "" ) ? "Briefings Created Successfully" : "Briefings Updated Successfully"
        }
        EventBus.$emit("success_resp", response)
        if(this.traverse == 1){
          setTimeout(()=>{
            this.$router.push({ path: "/distribute/briefings/"+uuid });
          },2500)
        } else {
          setTimeout(()=>{
            this.$router.push({ path: "/list_of_briefings" });
          },2500)
        }  
      } else {
        this.messsage_loader = false;
        let response ={
          popup:true,
          popupActive:true,
          msg:"Failed to Save Data"
        }
        EventBus.$emit("error_resp", response)
      }
    },
    check_success_fail() {
      for (let i = 0; i < this.form_status_box.length; i++) {
        if (this.form_status_box[i].status == "fail") {
          return false;
        }
      }
      return true;
    },
    async save_user_data() {
      this.form_status_box = [];
      let form_data = {
        title: this.form_data.briefing_title,
        function: this.form_data.functional_relevance,
        type: this.form_data.briefing_type,
        description: this.form_data.briefing_details,
        keynote: this.form_data.buller_point,
        video_public_id: this.video_public_id,
        fromDate: this.form_data.start_date,
        toDate: this.form_data.end_date
      };
      if ( this.briefingId == '' ) {
        await HTTP_formdata.post("briefings/add_briefings", form_data)
          .then(Response => {
            if (Response.status == 200) {
              this.form_status_box.push({ type: "form-data", status: "success" });
              this.user_uuid = Response.data.public_id;
              return JSON.stringify(Response.data.public_id);
            }
          })
          .catch(error => {
            let parsed_value = JSON.parse({ type: "form-data", status: "fail" });
            this.form_status_box.push(parsed_value);
            return error.status;
          });
      } else {
        await HTTP_formdata.put("briefings/edit_briefing/" + this.briefingId, form_data)
          .then(Response => {
            if (Response.status == 200) {
              this.form_status_box.push({ type: "form-data", status: "success" });
              this.user_uuid = Response.data.public_id;
              return JSON.stringify(Response.data.public_id);
            }
          })
          .catch(error => {
            let parsed_value = JSON.parse({ type: "form-data", status: "fail" });
            this.form_status_box.push(parsed_value);
            return error.status;
          });
      }
    },
    async save_upload_data(uuid, type) {
      let upload_data = new FormData();
      let URL = "";
      let img_count = 0;
      if (type == "picture") {
        for ( let i = 0; i < this.form_data.images.length; i++ ) {
          if ( this.form_data.images[i].image_file && this.form_data.images[i].image_file !="" ) {
            img_count++;
            upload_data.set( "briefingsImage" + img_count, this.form_data.images[i].image_file );
          }
        }
        URL = "briefings/upload_image/";
      } else if (type == "videos") {
        // this.upload_video();
      } else {
        for ( let i = 0; i < this.form_data.doc_files.length; i++ ) {
          if ( this.form_data.doc_files[i].file_data && this.form_data.doc_files[i].file_data != "" ) {
            img_count++;
            upload_data.set( "briefingsPdf" + img_count, this.form_data.doc_files[i].file_data );
          }
        }
        URL = "briefings/upload_pdf/";
      }
      if ( img_count > 0 ) {
        await HTTP_imageupload.post(URL + uuid, upload_data)
          .then(Response => {
            if (Response.status == 200) {
              this.form_status_box.push({ type: type, status: "success" });
            }
          })
          .catch(error => {
            this.form_status_box.push({ type: type, status: "fail" });
          });
      }
    },
    async upload_video() {
      let formData = new FormData();
      formData.set("briefingsVideo", this.video.sources[0].src);
      this.video_src = "";
      this.playerOptions.sources[0].src = "";

      // let Response = await axios.post(BASE_URL_VER + "briefings/upload_video", formData, { // uuid/" + uuid
      //     headers: {
      //       Authorization: "Bearer " + isloggedin.token,
      //       "Content-Type": "multipart/form-data"
      //     },
      //     onUploadProgress: function(progressEvent) {
      //       this.uploadPercentage = parseInt(
      //         Math.round((progressEvent.loaded / progressEvent.total) * 80)
      //       );
      //     }.bind(this)
      //   })
        await HTTP_imageupload.post("briefings/upload_video", formData, {
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 80)
            );
          }.bind(this)
        })
        .then(response => {
          if (response.status == 200) {
            // Use the returned data to set the necessary properties
            this.uploadPercentage = 100; // Set to 100% on successful upload
            this.video_public_id = response.data.public_id; // file_public_id
            this.video_src = response.data.file;
            this.playerOptions.sources[0].src = response.data.file;
            this.videoname = response.data.file_name;

            // Reset upload percentage after a short delay
            setTimeout(() => {
              this.uploadPercentage = 0;
            }, 1500);
          }
        })
        .catch(error => {
          this.uploadPercentage = 0;
          this.videoValidError = 'Error occurred while uploading. Please try again';
          this.video_src = "";
          this.playerOptions.sources[0].src = "";
          this.video_public_id = (this.video_public_id == "") ? this.video_public_id : 0;
        });

      // if (Response.status == 200) {
      //   this.form_status_box.push({ type: "video", status: "success" });
      // } else {
      //   this.form_status_box.push({ type: "video", status: "fail" });
      // }
    },
    mediaConvertStatusCheck() {
      HTTP.get( "media_converts/get_media_convert_status/" + this.video_public_id )
        .then(resp => {
          if ( resp.status == 200 ) {
            if ( resp.data.status == "IN-PROGRESS" ) {
              setTimeout(() => {
                this.mediaConvertStatusCheck();
              }, 5000);
            } else {
              this.uploadPercentage = this.uploadPercentage + 20
              setTimeout(() => {
                this.uploadPercentage = 0;
              }, 1500);
              this.video_src = resp.data.converted_file;
              this.playerOptions.sources[0].src = resp.data.converted_file;
            }
          }
        })
        .catch(error => {
          // if ( error.response.status == 400 ) {
            this.uploadPercentage = 0;
            this.videoValidError = 'Error occured while uploading. Please try again';
            this.video_src = "";
            this.playerOptions.sources[0].src = "";
            this.video_public_id = ( this.video_public_id == "" ) ? this.video_public_id : 0;
          // }
        });
    },
    getBriefingData() {
      this.messsage_loader = true;
      this.briefingId = this.$route.params.briefingId
      HTTP.get("briefings/get_briefing/" + this.briefingId )
        .then(response => {
          this.messsage_loader = false;
          if ( response.status == 200 && ( response.data[0].distributed == 0 || response.data[0].status == 'deactive' ) )  {
            this.form_data.briefing_title = response.data[0].title
            this.form_data.functional_relevance = response.data[0].function_id
            this.form_data.briefing_type = response.data[0].type
            this.form_data.briefing_details = response.data[0].content[0].description
            this.form_data.buller_point = response.data[0].content[0].keynote
            this.form_data.start_date = new Date(response.data[0].from_date.date)
            this.form_data.end_date = (response.data[0].to_date == "UFN") ? '' : new Date(response.data[0].to_date)
            this.stateStart.disabledDates.from = this.form_data.end_date;
            this.stateEnd.disabledDates.to = this.form_data.start_date;
            if ( response.data[0].media.length > 0 ) {
              this.form_data.upload_type = (response.data[0].media[0].image.length > 0) ? this.constants.PICTURE_RADIO_VALUE : (response.data[0].media[0].video.length > 0) ? this.constants.VIDEO_RADIO_VALUE : ""
              this.form_data.number_of_pictures = (response.data[0].media[0].image.length > 0) ? ((response.data[0].media[0].image.length == 3) ? '4' : response.data[0].media[0].image.length) : '1'
              if ( response.data[0].media[0].image.length > 0 ) {
                for (let i = 0; i < response.data[0].media[0].image.length; i++) {
                  this.form_data.images[i].display_name = response.data[0].media[0].image[i].path;
                  this.form_data.images[i].filename = response.data[0].media[0].image[i].filename;
                  this.briefingImages.push(this.form_data.images[i].display_name);
                }
              } else if ( response.data[0].media[0].video.length > 0 ) {
                this.video_src = response.data[0].media[0].video[0].path;
                this.playerOptions.sources[0].src = response.data[0].media[0].video[0].path;
              }
              if ( response.data[0].media[0].pdf.length > 0 ) {
                for (let i = 0; i < response.data[0].media[0].pdf.length; i++) {
                  this.form_data.doc_files.push({ file_name: response.data[0].media[0].pdf[i].display_name, file_data: "", dFile: response.data[0].media[0].pdf[i].filename });                  
                  this.slides.push({
                    id: i + 1,
                    title: response.data[0].media[0].pdf[i].display_name,
                    content: '<img src="' + require('@/assets/images/add-pdf.png') + '" title="Add PDF"/><br><p>' + response.data[0].media[0].pdf[i].display_name + "</p>"
                  });
                }
              }
            }
          } else {
            this.$router.push({path:"/list_of_briefings"})           
          }
        })
        .catch(error => {
          this.messsage_loader = false;
          this.$router.push({path:"/list_of_briefings"})  
        });      
    },
    mediaOptionChange(event) {
      if ( ( event == this.constants.PICTURE_RADIO_VALUE && this.video_src ) || ( event == this.constants.VIDEO_RADIO_VALUE && this.briefingImages.length > 0 ) ) {
        this.$bvModal.show('confirm-delete');
        let displayMsg = {
          briefingMediaChange: event,
          previousOption: this.form_data.upload_type
        }
        EventBus.$emit( "deleteSelected", displayMsg );
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    Object.assign(this.$data, initialState());
    this.$nextTick(() => {
      if (this.$refs.observer)
        this.$refs.observer.reset();
    });
    next();
  },
  mounted: function() {
    let date = new Date();
    date.setDate(date.getDate()-1);
    this.stateStart.disabledDates.to = this.stateEnd.disabledDates.to = moment( date ).toDate(); // new Date( date.toLocaleString() );
    this.functions = [];
    if ( isloggedin.roles[0] == 'ROLE_SUPERADMIN' ||  isloggedin.create_briefing == "global" ) {
      this.functions.push({ text: "Select", value: null, disabled:true },{ text: "All", value: "all" });
      HTTP.get("functions/display_functions")
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            this.functions.push({
              text: response.data[i].title,
              value: response.data[i].public_id
            });
          }
        })
        .catch(error => {});
    } else {
      this.functions.push({ text: "Select", value: null, disabled:true });
      let jsonData = {
        filter: "function",
        value: "",
        geographic_right: isloggedin.create_briefing
      }
      HTTP_formdata.post("training/training_filters", jsonData)
        .then(response => {
          if ( response.status == 200 ) {
            for ( let i = 0; i < response.data.values.length; i++ ) {
              this.functions.push({
                text: response.data.values[i].text,
                value: response.data.values[i].value
              });
            }
          }
        })
        .catch(error => {});
    }
    if ( this.$route.meta.route_header && this.$route.meta.route_header == 'Edit Briefing' ) {
      this.getBriefingData();
    }
  },
  computed: {
    currentImage: function() {
      return this.briefingImages[
        Math.abs(this.currentNumber) % this.briefingImages.length
      ];
    },
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  created() {
    EventBus.$on( "deleteConfirmation", response => {
      if ( this.form_data.upload_type == this.constants.PICTURE_RADIO_VALUE ) {
        this.video.sources[0].src = "";
        this.video_src = "";
        this.playerOptions.sources[0].src = "";
        this.video_public_id = 0;
      } else {
        this.form_data.images = [
          { image_file: "", display_name: "", filename: "" },
          { image_file: "", display_name: "", filename: "" },
          { image_file: "", display_name: "", filename: "" },
          { image_file: "", display_name: "", filename: "" }
        ];
        this.briefingImages = []
      }
    });
    EventBus.$on("closeConfirmDelete", newQuery => {
      // this.modalcomfirm = false;
      if ( this.form_data.upload_type == this.constants.PICTURE_RADIO_VALUE )
        this.form_data.upload_type = this.constants.VIDEO_RADIO_VALUE
      else
        this.form_data.upload_type = this.constants.PICTURE_RADIO_VALUE
    });
  },
  beforeCreate() {
    EventBus.$off("deleteConfirmation");
    EventBus.$off("closeConfirmDelete");
  }
};
</script>
<style scoped>
@import "./def_function.css";
.base-image-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
}
.file-input {
  display: none;
}
.vdp-datepicker {
    width: 100%;
}
.vueperslides.pdf-slider {
    padding: 0 30px;
}
</style>
